@import "../../assets/css/common.scss";
$mobileP: 515px;
$mobileL: 640px;
$tapP: 820px;
$tapL: 1050px;
$smalldestop: 1150px;
$mediumdestop: 1300px;
$largedesktop: 1600px;

.FooterContainer {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    text-align: center;
    font-family: "Notosans-Regular", sans-serif;
    margin-bottom:0px;
}
.stackStylesFooter1 {
  background-color: var(--colorPrimary);
  display: block;
  flex-flow: inherit;
  width: 100%;
  padding: 15px 0 !important;
  color: #fff;
  box-sizing: border-box;
  margin-bottom:0px;
  bottom:0px;
  font-size: 12px;
  @media only screen and (max-width: 1050px) {
    padding: 20px 0 !important;
  }
  .footerLeft {
    @media only screen and (max-width: 1050px) {
      text-align: center;
    }
  }
  .footerRight {
    text-align: right;
    @media only screen and (max-width: 1050px) {
      text-align: center;
    }
  }
  .footerCenter {
    text-align: center;
    @media only screen and (max-width: 1050px) {
      padding: 10px 0;
    }
    a {
      color: white;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .ftr {
    display: flex;
    text-decoration: none;
    margin-right: 100px;
    color: #fff;
    font-family: "Notosans-Regular", sans-serif;
    font-size: 12px;
    @media (max-width: 768px) {
      margin-right: 15px;
    }
    @media (max-width: 480px) {
      justify-content: center;
    }
  }
  .fntColor {
    color: #fff;
  }
  .privacyPolicy {
    margin-right: 5px !important;
    color: #fff;
  }
  .itemStyles {
    align-items: center;
    display: flex;
    margin-right: 20px;
    padding: 10px 0;
    font-size: 12px;
    text-decoration: none;
    width: 500px;
    &.pull-end {
      justify-content: right;
      margin-right: 0;
    }
    &.pull-center {
      justify-content: center;
      margin-left: 145px;
    }
    .TextDeco {
      text-decoration: none;
      line-height: 18px;
      font-size: 0.75rem;
      font-family: "Notosans-Regular", sans-serif;
      color: var(--colorPrimary);
      &:hover {
        color: var(--colorPrimaryMedium);
        line-height: 18px;
        font-size: 0.8125rem;
      }
      &:active {
        color: var(--colorPrimaryMedium);
        line-height: 18px;
        font-size: 0.8125rem;
      }
    }
    @media (max-width: $smalldestop) {
      padding: 0px;
      font-size: 0.8125rem;
      width: 500px;
      &.pull-end {
        justify-content: right;
        margin-right: 0;
      }
      &.pull-center {
        justify-content: center;
        margin-right: 0;
      }
    }
    @media (max-width: $tapP) {
      padding: 0px;
      font-size: 0.8125rem;
      width: 500px;
      &.pull-end {
        justify-content: right;
        margin-right: 0;
      }
      &.pull-center {
        justify-content: center;
        margin-right: 0;
      }
    }
    @media (max-width: $mobileP) {
      width: 500px;
      display: contents;
    }
  }
  @media (min-width: 768px) {
    display: flex;
    padding: 0 10px;
  }
  @media (max-width: 767px) {
    float: left;
    margin-bottom: 10px;
    padding: 10px 20px;
    text-align: center;
  }
}
.youtube {
  margin-top: 02px;
}

.socilaMedia{
    cursor:pointer;
}
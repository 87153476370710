.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.main-wrapper {   
    top: 50px;
    left: 0;
    width: 100%;
    text-align: center;   
}
.layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.layout main {
    flex-grow: 1;
}

.fui-MenuPopover {
    background-color: #4800ff !important;
    display: flex !important;
    margin: 3px !important;
    border-style: none !important;
    color:#fff !important;
}
.primarybutton {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border: 2px solid #3498db;
    color: #3498db;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
}
/* Hover effect */
.primarybutton:hover {
    background-color: #3498db;
    color: #fff;
}

/* Media query for smaller screens */
@media screen and (max-width: 600px) {
    .primarybutton {
        padding: 8px 16px;
        font-size: 14px;
    }
}
@import '../../assets/css/common.scss';
@import '../../assets/css/themeVariable.css';
$mobileP: 515px;
$mobileL: 640px;
$tapP: 820px;
$tapL: 1050px;
$smalldestop: 1150px;
$mediumdestop: 1300px;
$largedesktop: 1600px;

.ms-ContextualMenu-link:focus,
.ms-contextualMenu:focus {
    background: #fff !important;
}

.header-wrapper {
    height: 48px;
    font-size: var(--TextSmall);

    .leftList {
        display: flex;
        margin-top: 09px;

        .logoImg {
            height: 40px;
            margin: 0 0 0 0;

            @media only screen and (max-width:400px) {
                height: 20px;
            }
        }

        .appName {
            margin: 4px 0 0 10px;
            height: 22px;

            @media only screen and (max-width:400px) {
                height: 18px;
            }
        }

        .headerlogoLine {
            height: 36px;
            border-left: 1px solid #999;
            margin: -5px 0 0 4px;
        }

        @media only screen and (max-width: $tapL) {
            width: 100%;
            justify-content: center;
        }
    }

    .CenterBox {
        width: 100%;

        .searchbox {
            width: 100%;

            .searchIcon {
                right: 0;
                cursor: pointer;
            }

            .ms-BasePicker-text {
                min-width: 100%;

                .ms-BasePicker-itemsWrapper {
                    max-width: 100%;
                    min-width: 100%;

                    .ms-TagItem {
                        min-width: 92%;
                        background: #fff;
                        color: rgb(50, 49, 48);

                        .ms-TagItem-text {
                            margin: 0px 1px;
                            background: rgb(243, 242, 241);
                            border-radius: 2px;
                            padding: 3px 5px;
                        }

                        .ms-Button {
                            margin-left: auto;

                            &.is-selected {
                                background: none;
                                color: rgb(50, 49, 48);
                            }
                        }
                    }
                }
            }
        }

        @media only screen and (max-width: $tapL) {
            width: 80%;
            margin: 10px auto 0 auto;
        }
    }

    .rightList {
        .inlineList {
            list-style: none;
            padding-left: 0;
            margin-bottom: 0;
            margin-top: 19px;
            display: flex;
            justify-content: end;
            font-size: var(--TextSmall);
            @media only screen and (max-width: $tapL) {
                justify-content: center;
            }

            li:last-child {
                padding: 0px;
            }

            > li {
                position: relative;
                height: 45px;
                padding: 0 10px;
                overflow: hidden;

                &:last-child {
                    padding: 0px;
                }

                @include mobile {
                    padding: 0 12px;
                }

                &:nth-child(2):after {
                    content: '';
                    background: #ccc;
                    width: 1px;
                    height: 25px;
                    position: absolute;
                    right: 0px;
                    top: 10px;
                }

                a {
                    padding: 5px 6px 2px 6px;
                    display: inline-block;
                    font-size: 19px;
                    color: var(--colorPrimary);
                    text-decoration: none;
                    margin: 7px 0 0 0;

                    &:focus-visible {
                        border: none;
                    }

                    &.UserA {
                        display: flex;
                        align-items: center;
                        font-size: 12px;
                        color: #222;
                        white-space: nowrap;
                        padding-top: 2px;

                        &:hover {
                            background: none;
                            color: #222;
                        }

                        &img {
                            margin-right: 7px;
                        }
                    }
                    // &:hover {
                    //     background-color: var(--colorPrimary);
                    //     color: #fff;
                    //     border-radius: 4px;
                    // }
                }
            }
        }

        .notificationCount {
            color: #000;
            background: var(--colorSecondary);
            border-radius: 100% !important;
            position: absolute;
            top: 5px;
            left: 23px;
            font-size: 9px;
            width: 20px;
            height: 20px;
            padding: 4px 0px 0 0px;
            text-align: center;
            font-style: normal;
            font-weight: 700 !important;
            cursor: pointer;

            &.cartcount {
                left: 30px !important;
            }
        }

        .notificationUser,
        .cartList,
        .notificationList,
        .graphList,
        .userList {
            height: 15px;

            @media only screen and (max-width: $tapL) {
                height: 18px;
            }
        }

        .UserA {
            .userList {
                height: 30px;
                margin: -3px 07px 0 0;
                width: auto;
            }
        }

        .userList {
            height: 20px;
            width: 20px;
            position: relative;
            margin: -5px;
        }

        @media only screen and (max-width: $tapL) {
            width: 100%;
        }
    }

    @media only screen and (max-width: $tapL) {
        height: auto;
    }
}

.clearBoth {
    clear: both;
}

.gendetail_Icon {
    font-size: 16px;
    margin-top: -05px;
}

.userProfile_downarrow {
    content: '';
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid black;
    border-bottom: none;
    margin: 02px 0 0 10px;
}



.ms-TagItem button span .ms-Icon {
    height: auto;
}

.ms-SelectionZone .ms-BasePicker-text {
    border: 1px solid #ccc;
    border-radius: 4px;
}

.searchbox .searchIcon {
    border-radius: 6px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.ms-SelectionZone .ms-BasePicker-text:hover {
    border-color: var(--colorPrimary);
}

.ms-SelectionZone .ms-BasePicker-text::after {
    border: 1px solid rgb(0, 120, 212);
    border-radius: 6px;
}

.ms-Callout-container .ms-Callout {
    // min-width: 430px !important;
    width: auto;
}

.imgContacts {
    padding-right: 10px;
    max-width: 25px;
}

.header-wrapper .rightList .inlineList li:last-child {
    padding: 0px;
}

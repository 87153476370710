
/* cyrillic-ext */
@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/notosans/v32/o-0IIpQlx3QUlC5A4PNr6DRAW_0.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/notosans/v32/o-0IIpQlx3QUlC5A4PNr4TRAW_0.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* devanagari */
@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/notosans/v32/o-0IIpQlx3QUlC5A4PNr5DRAW_0.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* greek-ext */
@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/notosans/v32/o-0IIpQlx3QUlC5A4PNr6TRAW_0.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/notosans/v32/o-0IIpQlx3QUlC5A4PNr5jRAW_0.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/notosans/v32/o-0IIpQlx3QUlC5A4PNr6jRAW_0.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/notosans/v32/o-0IIpQlx3QUlC5A4PNr6zRAW_0.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/notosans/v32/o-0IIpQlx3QUlC5A4PNr5TRA.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}



html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    font-family: 'Notosans-Regular', sans-serif;
    line-height: normal;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

.clearfix {
    clear: both !important;
}


@mixin mediumDesktop {
    @media only screen and (max-width: 1440px) {
        @content;
    }
}

@mixin desktop {
    @media only screen and (max-width: 1366px) {
        @content;
    }
}

@mixin smallDesktop {
    @media only screen and (max-width: 1280px) {
        @content;
    }
}

@mixin ipadLandscape {
    @media only screen and (max-width: 1024px) {
        @content;
    }
}

@mixin fromIpadMini {
    @media only screen and (min-width: 1280px) {
        @content;
    }
}



@mixin fromIpad {
    @media only screen and (min-width: 768px) {
        @content;
    }
}

@mixin ipadMini {
    @media only screen and (max-width: 820px) {
        @content;
    }
}

@mixin ipad {
    @media only screen and (max-width: 768px) {
        @content;
    }
}

@mixin mobile {
    @media only screen and (max-width: 600px) {
        @content;
    }
}

.MuiDataGrid-columnHeaders {
    background-color: #747dd9 !important;
    color: #000 !important;
}
//.MuiDataGrid-toolbarContainer
.MuiButton-root {
    background-color: #facf03 !important;
    color: #000 !important;
}

.MuiInputBase-root{
    height:40px;
}

.MuiFormControl-root {
    margin: 10px !important;
}
@import '../../assets/css/common.scss';

$mobileP: 515px;
$mobileL: 640px;
$tapP: 820px;
$tapL: 1050px;
$smalldestop: 1150px;
$mediumdestop: 1300px;

.navigation-wrapper {
    float: left;
    width: 100%;
    box-sizing: border-box;
    font-size: var(--TextLarge);
    top:10px;

    .headerNavigation {
        background: var(--colorPrimary);
        float: left;
        position: relative;
        width: 100%;
        color: #fff;
        display: flex;

        > ul {
            list-style: none;
            margin: 0;
            height: 38px;
            padding: 0 8px;
            display: flex;
            width: 70%;

            @media (max-width: $tapL) {
                width: 100%;
            }

            > li {
                margin-left: 70px;
                position: relative;
                height: 36px;

                &:first-child {
                    a {
                        margin-left: 0px !important;

                        @include ipadMini {
                            margin-left: 8px !important;
                        }
                    }
                }

                &.DropdownIcon {
                    &::after {
                        position: absolute;
                        content: ' ';
                        top: 15px;
                        right: 0px;
                        width: 6px;
                        height: 6px;
                        border-right: 1.7px solid #fff;
                        border-top: 1.7px solid #fff;
                        transform: rotate(135deg);

                        @media (max-width: $tapP) {
                            right: 10px;
                        }
                    }
                }

                > a {
                    margin: 10px 11px 10px 11px !important;
                    float: left;
                    font-size: var(--TextLarge);
                    font-family: 'Notosans-Regular', sans-serif;
                    text-decoration: none;
                    color: #fff;

                    &.active {
                        display: inline-block;
                        position: relative;
                    }

                    &:hover {
                        display: inline-block;
                        position: relative;
                    }

                    &.active::after {
                        display: block;
                        position: absolute;
                        left: 0px;
                        right: 0px;
                        content: "";
                        bottom: 0;
                        top: 23px;
                        height: 0.14rem;
                        border-bottom: 3px solid var(--colorSecondary);
                    }

                    &:hover::after {
                        display: block;
                        position: absolute;
                        left: 0px;
                        right: 0px;
                        content: "";
                        bottom: 0;
                        top: 23px;
                        height: 0.14rem;
                        border-bottom: 3px solid var(--colorSecondary);
                    }

                    .fa {
                        margin-right: 5px;
                    }

                    @media only screen and (max-width: $tapP) {
                        width: 100%;
                        display: block;
                    }
                }

                @media only screen and (max-width: $tapP) {
                    width: 100%;
                    display: block;
                    margin-left: 0;
                }

                &:hover > ul {
                    display: block;
                }

                ul {
                    position: absolute;
                    top: 38px;
                    left: 0px;
                    z-index: 100;
                    background: var(--colorPrimary);
                    min-width: 160px;
                    display: none;

                    &:before {
                        content: "";
                    }

                    @media only screen and (max-width: $tapP) {
                        position: relative;
                        top: 05px;
                        box-sizing: border-box;
                        padding-left: 20px;
                    }

                    > li {
                        position: relative;
                        width: 100%;
                        float: left;

                        .angleRightArrow {
                            margin-left: 0.5rem !important;
                            font-family: fontawesome, sans-serif;
                            position: absolute;
                            right: 10px;
                            top: 13px;
                            color: #fff;
                        }

                        &.DropdownIcon {
                            &::after {
                                position: absolute;
                                content: ' ';
                                top: 17px;
                                right: 10px;
                                width: 6px;
                                height: 6px;
                                border-right: 2px solid #fff;
                                border-top: 2px solid #fff;
                                transform: rotate(50deg);
                            }
                        }

                        &:hover {

                            > .angleRightArrow {
                                color: #fff;
                            }

                            > ul {
                                display: block;
                            }
                        }

                        a {
                            padding: 10px;
                            width: 100%;
                            float: left;
                            text-decoration: none;
                            color: #fff;

                            &:hover {
                                background: var(--colorPrimaryDark);
                                color: #fff;
                            }
                        }

                        > ul {
                            position: absolute;
                            left: 100%;
                            display: none;
                            top: 0px;

                            @media only screen and (max-width: $tapP) {
                                position: relative;
                                left: 0;
                                box-sizing: border-box;
                                padding-left: 40px;
                            }

                            > li {
                                &.DropdownIcon {
                                    &::after {
                                        position: absolute;
                                        content: ' ';
                                        top: 17px;
                                        right: 10px;
                                        width: 6px;
                                        height: 6px;
                                        border-right: 2px solid #fff;
                                        border-top: 2px solid #fff;
                                        transform: rotate(50deg);
                                    }
                                }

                                ul {
                                    display: none;
                                }

                                &:hover {
                                    ul {
                                        display: block;
                                    }

                                    .angleRightArrow {
                                        color: white;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            @media (max-width: $tapP) {
                display: block;
            }
        }

        .downFontIcon {
            margin-left: 0.5rem !important;
            font-family: fontawesome, sans-serif;
        }


        .navBarSideMenu {
            width: 30%;
            display: flex;
            padding: 0 25px;
            align-items: center;

            @media (max-width: $tapL) {
                position: absolute;
                top: 42px;
                right: 20px;
                border-radius: 4px;
                padding: 6px 10px;
                background: var(--colorPrimary);
                width: auto;
            }

            @media (max-width: $tapP) {
                position: relative;
                top: 43px;
                right: 20px;
                border-radius: 4px;
                padding: 6px 10px;
                background: var(--colorPrimary);
                width: auto;
                float: right;
            }
        }

        .navBarSideMenu ul {
            width: 100%;
            margin-left: auto;
            display: flex;
            justify-content: end;
            flex-direction: row-reverse;
        }


        .navBarSideMenu ul li {
            color: #ffffff;
            margin: 7px 0px 0 15px;
        }

        @media (max-width: $tapP) {
            display: block;
        }
    }

    .menuBars {
        position: absolute;
        top: 19px;
        color: var(--colorPrimary);
        display: none;
        right: 38px;

        @media only screen and (max-width: $tapP) {
            display: block;
        }

        .clear-both {
            clear: both;
        }
    }
}

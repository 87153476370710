:root {
    --colorPrimary: #140a9a;
    --colorPrimaryDark: #0c065c;
    --colorPrimaryMedium: #253fc8;
    --colorPrimaryLight: #e2f2ff;
    --colorSecondary: #ffc82f;
    --colorSecondaryDark: #cca026;
    --colorSecondaryMedium: #ffd359;
    --colorSecondaryLight: #ffde82;
    --colorActionSuccess: #2bb673;
    --colorActionFail: #ff0000;
    --colorActionWarning: #f37021;
    --colorActionInfo: #6699ff;
    --colorNeutralMedium: #E4E6E6;
    --colorNeutralLight: #F9F9F9;
    --colorNeutralDark: #868686;
    --TypoRegular: 'Notosans-Regular';
    --TypoMedium: 'Notosans-Medium';
    --TypoSemibold: 'Notosans-Semibold';
    --TypoBold: 'Notosans-Bold';
    --Typolight: 'Notosans-Light';
    --TypoItalic: 'Notosans-Italic';
    --HeadSize1: 1.5rem; /* 24px */
    --HeadSize2: 1.375rem; /* 22px */
    --HeadSize3: 1.25rem; /* 20px */
    --HeadSize4: 1.125rem; /* 18px */
    --HeadSize5: 1rem; /* 16px */
    --HeadSize6: .875rem; /* 0.875 */
    --TextSmaller: 0.5rem; /* 8px */
    --TextxSmall: 0.625rem; /* 10px */
    --TextSmall: 0.75rem; /* 12px */
    --TextRegular: 0.813rem; /* 13px */
    --TextMedium: 0.875rem; /* 14px */
    --TextLarge: 0.938rem; /* 15px */
    --TextxLarge: 1rem; /* 16px */
}
